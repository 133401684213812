/* eslint-disable max-lines */
import { z } from "zod";

import { MoneySchema } from "../lib/Money/types";

const responseErrorSchema = z.object({
  code: z.string(),
  id: z.string(),
  status: z.string(),
  title: z.string(),
});
export type ResponseError = z.infer<typeof responseErrorSchema>;

export enum VisitType {
  ADMISSION = "ADMISSION",
  EVALUATION = "EVALUATION",
  REGULAR = "REGULAR",
  RECERTIFICATION = "RECERTIFICATION",
  DISCHARGE = "DISCHARGE",
  SUPERVISORY = "SUPERVISORY",
  RESUMPTION_OF_CARE = "RESUMPTION_OF_CARE",
}

export enum VisitStatus {
  OPEN = "OPEN",
  FILLED = "FILLED",
  CONFIRMED = "CONFIRMED",
  PENDING = "PENDING",
  CANCELED = "CANCELED",
  CLOSED = "CLOSED",
  LOGGED = "LOGGED",
}

export const visitWorkerSchema = z.object({
  userId: z.string(),
  name: z.string(),
});
export type VisitWorker = z.infer<typeof visitWorkerSchema>;

export enum VisitOccurrenceStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum PricingType {
  PER_VISIT = "PER_VISIT",
  PER_HOUR = "PER_HOUR",
}
export const pricingTypeSchema = z.nativeEnum(PricingType);

export const visitOccurrenceSchema = z.object({
  id: z.string(),
  attributes: z.object({
    status: z.nativeEnum(VisitOccurrenceStatus),
    paid: z.boolean(),
    pricingType: pricingTypeSchema,
    completedAt: z.string(),
    estimatedDuration: z.number().optional(),
    visitId: z.string(),
  }),
  type: z.literal("visitOccurrence"),
});
export type VisitOccurrence = z.infer<typeof visitOccurrenceSchema>;

export const visitOccurenceResponseSchema = z.object({
  data: visitOccurrenceSchema,
  errors: z.array(responseErrorSchema).optional(),
});
export type VisitOccurenceResponse = z.infer<typeof visitOccurenceResponseSchema>;

export const visitOccurencesResponseSchema = z.object({
  data: z.array(visitOccurrenceSchema),
  errors: z.array(responseErrorSchema).optional(),
});
export type VisitOccurencesResponse = z.infer<typeof visitOccurencesResponseSchema>;
export const visitMissingRequirements = z.object({
  reqId: z.string(),
  name: z.string(),
  visibleToHCP: z.boolean(),
});
export type VisitMissingRequirements = z.infer<typeof visitMissingRequirements>;

export const visitSchema = z.object({
  id: z.string(),
  attributes: z.object({
    caseId: z.string(),
    status: z.nativeEnum(VisitStatus),
    type: z.nativeEnum(VisitType),
    bookedWorkerId: z.string().nullable().optional(),
    details: z.string().optional(),
    // TODO remove optional once BE fixes it
    payRate: MoneySchema.optional(),
    // only for ADMISSION and RESUMPTION_OF_CARE visit types
    deadline: z.string().optional(),
    // Time by which the visit must be confirmed to avoid score penalty
    confirmationDeadline: z.string().optional(),
    // Time when the visit was marked as pending
    pendedAt: z.string().optional(),
    // Date of the first visit
    confirmedVisitDate: z.string().optional(),
    // Reason for pending the visit
    pendingVisitReason: z.string().optional(),
    // only for REGULAR visit type
    workerReq: z.string().optional(),
    // only for REGULAR visit type
    visitsPerWeek: z.number().optional(),
    // only for REGULAR visit type
    durationInWeeks: z.number().optional(),
    // only for REGULAR visit type
    pricingType: pricingTypeSchema,
    // only for REGULAR visit type
    estimatedStartTime: z.string().nullable().optional(),
    // only for REGULAR visit type
    estimatedDuration: z.number().optional(),
    visitOccurrences: z.array(visitOccurrenceSchema).optional(),
    // will be missing for OPEN visits
    bookedAt: z.string().optional(),
    // only for OPEN visits
    missingRequirements: z.array(visitMissingRequirements).optional(),
    daysOfWeek: z.array(z.string()).optional(),
  }),
  type: z.literal("visit"),
});

export type Visit = z.infer<typeof visitSchema>;

export const visitResponseSchema = z.object({
  data: visitSchema,
  errors: z.array(responseErrorSchema).optional(),
});
export type VisitResponse = z.infer<typeof visitResponseSchema>;

export const bookVisitRequestSchema = z.object({
  data: z.object({
    attributes: z.object({
      bookedWorkerId: z.string().nullable(),
    }),
    type: z.literal("visit"),
  }),
});
export type BookVisitRequest = z.infer<typeof bookVisitRequestSchema>;

export const bookVisitResponseSchema = visitResponseSchema;
export type BookVisitResponse = z.infer<typeof bookVisitResponseSchema>;

export const confirmVisitRequestSchema = z.object({
  data: z.object({
    attributes: z.object({
      confirmedVisitDate: z.string(),
    }),
    type: z.literal("visit"),
  }),
});
export type ConfirmVisitRequest = z.infer<typeof confirmVisitRequestSchema>;

export const pendVisitRequestSchema = z.object({
  data: z.object({
    attributes: z.object({
      pendingVisitReason: z.string(),
    }),
    type: z.literal("visit"),
  }),
});
export type PendVisitRequest = z.infer<typeof pendVisitRequestSchema>;

export const logVisitRequestSchema = z.object({
  data: z
    .object({
      ...visitOccurrenceSchema.shape,
      attributes: visitOccurrenceSchema.shape.attributes.pick({
        completedAt: true,
        pricingType: true,
        estimatedDuration: true,
      }),
    })
    .omit({ id: true }),
});
export type LogVisitRequest = z.infer<typeof logVisitRequestSchema>;

export const logVisitResponseSchema = visitOccurenceResponseSchema;
export type LogVisitResponse = z.infer<typeof logVisitResponseSchema>;

export const dropVisitRequestSchema = z.undefined();
export type DropVisitRequest = z.infer<typeof dropVisitRequestSchema>;

export const dropVisitResponseSchema = visitResponseSchema;
export type DropVisitResponse = z.infer<typeof dropVisitResponseSchema>;

export enum CaseStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}

export const patientSchema = z.object({
  id: z.string(),
  attributes: z.object({
    createdAt: z.string(),
    workplaceId: z.string(),
    externalPatientIdentifier: z.string(),
    formattedAddress: z.string(),
    latitude: z.number(),
    longitude: z.number(),
    oasis: z.boolean(),
  }),
  type: z.literal("patient"),
});
export type Patient = z.infer<typeof patientSchema>;

export const workplaceSchema = z.object({
  id: z.string(),
  attributes: z.object({
    name: z.string(),
    userId: z.string(),
  }),
  type: z.literal("workplace"),
});
export type Workplace = z.infer<typeof workplaceSchema>;

export const caseSchema = z.object({
  attributes: z.object({
    createdAt: z.string(),
    status: z.nativeEnum(CaseStatus),
    patient: patientSchema,
    specialties: z.array(z.string()),
    description: z.string(),
    workplace: workplaceSchema,
    visits: z.array(visitSchema),
    instantPay: z.boolean().optional(),
  }),
  id: z.string(),
  type: z.literal("case"),
});
export type Case = z.infer<typeof caseSchema>;

export const caseFiltersSchema = z.object({
  filter: z
    .object({
      latitude: z.number().optional(),
      longitude: z.number().optional(),
      maxDistanceInMiles: z.number().optional(),
      facilityId: z.string().optional(),
      booked: z.boolean().optional(),
      status: z.nativeEnum(CaseStatus).optional(),
      qualifications: z.string().optional(),
      visitId: z.string().optional(),
    })
    .optional(),
});
export type CaseFilters = z.infer<typeof caseFiltersSchema>;

export const caseResponseSchema = z.object({
  data: caseSchema,
  errors: z.array(responseErrorSchema).optional(),
});
export type CaseResponse = z.infer<typeof caseResponseSchema>;

export const casesResponseSchema = z.object({
  data: z.array(caseSchema),
  errors: z.array(responseErrorSchema).optional(),
});
export type CasesResponse = z.infer<typeof casesResponseSchema>;

export const workerStatisticsSchemaAttributes = z.object({
  bookedVisits: z.number(),
  openCasesCount: z.number(),
  openVisitsCount: z.number(),
});

export const workerStatisticsDataSchema = z.object({
  attributes: workerStatisticsSchemaAttributes,
});

export const workerStatisticsResponseSchema = z.object({
  data: workerStatisticsDataSchema,
});

export type WorkerStatisticsResponse = z.infer<typeof workerStatisticsResponseSchema>;

export enum WorkerRequirements {
  RN = "RN",
  LVN = "LVN",
  CNA = "CNA",
  CAREGIVER = "Caregiver",
  CHHA = "CHHA",
  PT = "Physical Therapist",
  PTA = "Physical Therapist Assistant",
}
export const WORKER_REQUIREMENTS: WorkerRequirements[] = [
  WorkerRequirements.RN,
  WorkerRequirements.LVN,
  WorkerRequirements.CNA,
  WorkerRequirements.CAREGIVER,
  WorkerRequirements.CHHA,
  WorkerRequirements.PT,
  WorkerRequirements.PTA,
];

export const BAA_SIGNATURE_NAME = "business-associate-agreement";

export const signatureSchema = z.object({
  attributes: z.object({
    documentName: z.literal(BAA_SIGNATURE_NAME),
    version: z.string(),
  }),
  type: z.literal("signature"),
});

export type Signature = z.infer<typeof signatureSchema>;

export const addSignatureRequestSchema = z.object({
  data: signatureSchema.extend({
    attributes: signatureSchema.shape.attributes.extend({
      signedDocumentHTML: z.string(),
    }),
  }),
});
export type AddSignatureRequest = z.infer<typeof addSignatureRequestSchema>;

export const addSignatureResponseSchema = z.object({
  data: signatureSchema,
  errors: z.array(responseErrorSchema).optional(),
});
export type AddSignatureResponse = z.infer<typeof addSignatureResponseSchema>;

export const signaturesResponseSchema = z.object({
  data: z.array(signatureSchema),
  errors: z.array(responseErrorSchema).optional(),
});
export type SignaturesResponse = z.infer<typeof signaturesResponseSchema>;
/* eslint-enable max-lines */
