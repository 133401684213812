import { formatShortDateWithWeekday } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Chip, Divider, Stack } from "@mui/material";
import { startCase, toLower } from "lodash";

import { PricingType, type Visit, VisitType } from "../types";

interface Props {
  visit: Visit;
  instantPay?: boolean;
}

const daysOfWeekMapping: Record<string, string> = {
  Sunday: "Sun",
  Monday: "Mon",
  Tuesday: "Tue",
  Wednesday: "Wed",
  Thursday: "Thu",
  Friday: "Fri",
  Saturday: "Sat",
};

// eslint-disable-next-line complexity
export function HomeHealthCaseVisitDetails(props: Props) {
  const { visit, instantPay } = props;
  const { attributes } = visit ?? {};

  const { deadline, daysOfWeek } = attributes ?? {};

  const formattedDaysOfWeek: string[] = [];
  for (const day of Object.keys(daysOfWeekMapping)) {
    if (daysOfWeek && Array.isArray(daysOfWeek) && daysOfWeek.includes(day)) {
      formattedDaysOfWeek.push(daysOfWeekMapping[day]);
    }
  }

  return (
    <Stack width="100%" alignItems="flex-start">
      <Text bold>
        {startCase(toLower(visit.attributes.type))}{" "}
        {[VisitType.REGULAR].includes(visit.attributes.type) && ` Visits`}
        {[
          VisitType.REGULAR,
          VisitType.ADMISSION,
          VisitType.EVALUATION,
          VisitType.DISCHARGE,
        ].includes(visit.attributes.type) && ` - ${visit.attributes.workerReq!}`}
      </Text>
      {visit.attributes.type === VisitType.REGULAR &&
        visit.attributes.pricingType === PricingType.PER_HOUR && (
          <Chip size="small" label="Hourly" color="info" />
        )}
      {([VisitType.ADMISSION, VisitType.RESUMPTION_OF_CARE].includes(visit.attributes.type) ||
        (visit.attributes.type === VisitType.REGULAR &&
          visit.attributes.pricingType === PricingType.PER_VISIT)) &&
        isDefined(deadline) && (
          <Text variant="subtitle2">
            Deadline: {formatShortDateWithWeekday(visit.attributes.deadline!)}
          </Text>
        )}
      {visit.attributes.type === VisitType.REGULAR && (
        <Text variant="subtitle2">
          {visit.attributes.visitsPerWeek!} per week, {visit.attributes.durationInWeeks!}
          {visit.attributes.durationInWeeks! > 1 ? " weeks total" : " week"}
        </Text>
      )}
      {visit.attributes.type === VisitType.REGULAR &&
        visit.attributes.pricingType === PricingType.PER_HOUR && (
          <>
            {isDefined(visit.attributes.estimatedStartTime) && (
              <Text variant="subtitle2">Starts at {visit.attributes.estimatedStartTime}</Text>
            )}
            <Text variant="subtitle2">Duration {visit.attributes.estimatedDuration!} hours</Text>
            {isDefined(deadline) && (
              <Text variant="subtitle2">Start Date: {formatShortDateWithWeekday(deadline)}</Text>
            )}
            {isDefined(daysOfWeek) && daysOfWeek.length > 0 && (
              <Text sx={{ fontSize: "0.75rem" }} variant="subtitle2">
                Availability: {formattedDaysOfWeek.join(", ")}
              </Text>
            )}
          </>
        )}
      <Stack direction="row" spacing={1}>
        <Text bold color="primary">
          ${(visit.attributes.payRate?.amountInMinorUnits ?? 0) / 100}{" "}
          {visit.attributes.type === VisitType.REGULAR &&
            (visit.attributes.pricingType === PricingType.PER_VISIT ? " per visit" : " per hour")}
        </Text>
        {instantPay && (
          <>
            <Divider flexItem orientation="vertical" sx={{ borderRightWidth: "medium" }} />
            <Text bold color={(theme) => theme.palette.success.light}>
              InstantPay - Trusted
            </Text>
          </>
        )}
      </Stack>
    </Stack>
  );
}
